import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkLayout from "../../components/work-layout";
import slide1 from '../../../../app/assets/img/work/scherago/main.png';
import slide2 from '../../../../app/assets/img/work/scherago/publisher-list.png';
import slide3 from '../../../../app/assets/img/work/scherago/reports.png';
import slide4 from '../../../../app/assets/img/work/scherago/invoice.png';

const works = [
    {'title': 'CompanyThread', 'url': '/work/company-thread'},
    {'title': 'Bandwaggon', 'url': '/work/bandwaggon'},
    {'title': 'Scherago', 'url': '/work/scherago'},
];

const slides = [
    slide1,
    slide2,
    slide3,
    slide4,
];

const selected = works[2];
const next = works[0];
const prev = works[1];

const description = `
Scherago portfolio.
My experience working on single page application CRM for magazine advertising representative.
Tools: PHP & Yii, Javascript & Backbone.js
`;

const keywords = ['Portfolio', 'Scherago', 'Web Developer', 'Backbone', 'JavaScript', 'Yii', 'PHP', 'CRM', 'single page application'];

const SecondPage = () => (
  <Layout>
      <SEO title="Scherago - Portfolio" description={description} keywords={keywords} />
      <WorkLayout next={next} prev={prev} title={selected.title} slides={slides}>
          <p>
              Being part of Clevertech I worked on several CRM systems, this one is quite different though — it was a
              first CRM we built as a single page application.
          </p>

          <div className="about-project">
              <p>{' '}
                  Scherago International is a magazine advertising representative who transferred their old
                  desktop based CRM system to the cloud{' '}
              </p>
          </div>
          <ul className="list-arrow-style tools">
              <li>PHP + MySQL + Yii framework</li>
              <li>JavaScript + jQuery + Backbone.js + RequireJS</li>
              <li>HTML + Twitter Bootstrap</li>
              <li>CSS + SASS + Compass</li>
          </ul>
      </WorkLayout>
  </Layout>
);

export default SecondPage
